<template>
  <b-overlay
    :show="overlay.main"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-alert
      class="p-2 mt-2"
      style="height: 100%;position: relative;"
      show
      variant="warning"
    >
      <span> {{ $t('add-mobile-warning') }}</span>
      <br>
      <br>
      <b-button
        class="mx-auto d-block w-25"
        :to="'/'+this.$i18n.locale+'/profile/general'"
      >
        {{ $t('profile') }}
      </b-button>
    </b-alert>
    <b-card
      v-if="withdrawal"
      no-body
    >
      <b-row>
        <b-col
          sm="6"
          class="card-border"
        >
          <b-card-body>
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t('withdraw-title') }}
              </h3>
              <p>{{ $t('withdraw-dec', { currency: formData.currency.currency }) }}</p>
            </div>
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
          </b-card-body>
        </b-col>
        <b-col sm="6">
          <b-card-body>
            <b-alert
              class="p-2"
              show
              variant="danger"
            >
              <p>{{ $t('withdraw-warning-dec', { currency: formData.currency.currency }) }}</p>
            </b-alert>
<!--            <b-alert-->
<!--              v-if="accountDetail.balance"-->
<!--              class="p-2 text-center"-->
<!--              show-->
<!--              variant="success"-->
<!--            >-->
<!--              <span>{{ $t('withdrawableAmount') }} : </span>-->
<!--              <span dir="ltr">-->
<!--                {{ accountDetail.balance }}-->
<!--              </span>-->
<!--            </b-alert>-->
            <div class="my-2">
              <metatraderAccountDetail
                :acc="accountDetail"
                :show-detail="!!formData.account"
              />            </div>
            <ValidationObserver
              ref="depositFormRef"
              v-slot="{ invalid }"
            >
              <b-form class="mt-2">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('forex.account-number')"
                      label-for="account-number"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('forex.account-number')"
                        vid="account-number"
                        rules="required"
                      >
                        <v-select
                          id="account-number"
                          v-model="formData.account"
                          :options="accountsItems"
                          :clearable="false"
                          label="account"
                          @input="onchangeAccountDetail"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('forex.platform')"
                      label-for="platform"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('forex.platform')"
                        vid="platform"
                        rules="required"
                      >
                        <v-select
                          id="platform"
                          v-model="formData.platform"
                          :options="platform"
                          label="name"
                          :dir="$store.state.appConfig.layout.isRTL?'rtl':'ltr'"
                          :clearable="false"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-for="coin"
                      :label="$t('coin')"
                    >
                      <v-select
                        v-model="formData.currency"
                        :options="currencyItems"
                        label="currencyNetwork"
                        :clearable="false"
                        :dir="$store.state.appConfig.layout.isRTL?'rtl':'ltr'"
                        @input="onCurrencyChange"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-for="network"
                      :label="$t('Network')"
                    >
                      <b-form-select
                        id="network"
                        v-model="formData.network"
                        :options="networkItems"
                        text-field="network"
                        value-field="network"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('destinationAddress')"
                      :rules="`required|cryptoWalletAddress:${symbol}`"
                    >
                      <b-form-group
                        label-for="destinationAddress"
                        :label="$t('destinationAddress')"
                      >
                        <b-form-input
                          id="destinationAddress"
                          v-model="formData.destinationAddress"
                          name="destinationAddress"
                          :placeholder="$t('destinationAddress')"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ $t(errors[0]) }}</span>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-center">
                    <b-button
                      variant="success"
                      @click="showBarcode =!showBarcode"
                    >
                      {{ $t('barcodeScanning') }}
                    </b-button>
                    <p
                      class="d-flex justify-content-center text-danger mt-1 error"
                    >
                      {{ $t(error) }}
                    </p>

                    <qrcode-stream
                      v-if="showBarcode"
                      @decode="onDecode"
                      @init="onInit"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >

                    <b-row align-v="center">
                      <b-col
                        cols="8"
                        md="8.5"
                        lg="9"
                      >
                        <b-form-group
                          :label="$t('Amount')"
                          label-for="Amount"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('Amount')"
                            rules="required|number_or_decimal|decimal_points:2"
                          >
                            <b-form-input
                              id="Amount"
                              v-model="formData.amount"
                              name="Amount"
                              :placeholder="$t('Amount')"
                              :formatter="numberFormat"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>

                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="4"
                        md="2.5"
                        lg="3"
                      >
                        <b-button
                          block
                          style="margin-top: 6px;"
                          @click="formData.amount = helpers.numberWithCommas(accountDetail.balance)"
                        >
                          <span style="white-space: nowrap;">max</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      data-toggle="modal"
                      data-target="#myModal"
                      variant="primary"
                      class="mt-2 btn-block"
                      @click="setInvoiceDetails"
                    >
                      {{ $t('withdrawal-request') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="!withdrawal">
      <h4
        class="text-justify"
        style="line-height: normal;"
      >
        {{ $t('withdrawal-desc') }}
      </h4>
    </b-card>
    <b-overlay
      :show="overlay.withdraw"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card
        v-if="items.length > 0"
        class="d-none d-sm-block"
        :title="$t('lastWithdrawal')"
      >
        <b-row align-h="center">
          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              striped
              sticky-header="1000px"
            >
              <template #cell(created_at)="data">
                <span>{{ getFormattedDate(data.item['date']) }}</span>
              </template>
              <template #cell(amount)="data">
                <span>{{ helpers.numberWithCommas(data.item.amount) }}</span>
              </template>
              <template #cell(detail.status)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    :variant="data.item.detail['status'] === 'pending' ? 'warning' : data.item.detail['status'] === 'successful' ? 'success' :data.item.detail['status']? 'danger':''"
                    size="sm"
                    style="display: block; width: 140px"
                    @click="openInfoTransactionModal(data.item)"
                  >
                    {{ $t(data.item.detail.status) }}
                  </b-button>
                  <feather-icon
                    v-if="data.item.detail['status'] === 'rejected' && data.item.detail['reject_reason']"
                    :id="`popover-button-variant-${data.item.id}`"
                    icon="HelpCircleIcon"
                    class="cursor-pointer mx-1"
                    size="20"
                    href="#"
                    tabindex="0"
                  />
                  <b-popover
                    v-if="data.item.detail['status'] === 'rejected' && data.item.detail['reject_reason']"
                    placement="top"
                    :target="`popover-button-variant-${data.item.id}`"
                    variant="secondary"
                    triggers="focus"
                  >
                    {{ data.item.detail['reject_reason'] ? data.item.detail['reject_reason']:'' }}
                  </b-popover>
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col cols="auto">
            <b-pagination
              v-model="currentpageTransactions"
              align="fill"
              size="sm"
              class="mb-1"
            />
          </b-col>
        </b-row>
      </b-card>

      <b-card
        class="d-sm-none"
        :title="$t('lastWithdrawal')"
      >
        <b-row align-h="center">
          <b-col cols="12">
            <div
              v-for="(item, index) in items"
              :key="index"
              :class="$store.state.appConfig.layout.skin === 'dark' ? 'card-bg' : 'transaction-card'"
              class="mb-2"
            >
              <div class="d-flex justify-content-between">
                <div>{{ $t('id') }}</div>
                <h5>{{ item.automation_id }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('coin') }}</div>
                <h5>{{ item.currency.currency }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('Network') }}</div>
                <h5>{{ item.currency.network }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('amount') }}</div>
                <h5>{{ helpers.numberWithCommas(item.amount) }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('date') }}</div>
                <h5>{{ getFormattedDate(item.date) }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('status') }}</div>
                <div class="d-flex align-items-center">
                  <feather-icon
                    v-if="item.detail['status'] === 'rejected' && item.detail.reject_reason"
                    :id="`popover-button-variant-${item.id}+mobile`"
                    icon="HelpCircleIcon"
                    class="cursor-pointer"
                    size="20"
                    href="#"
                    tabindex="0"
                  />
                  <b-popover
                    v-if="item.detail['status'] === 'rejected' && item.detail.reject_reason"
                    placement="top"
                    :target="`popover-button-variant-${item.id}+mobile`"
                    variant="secondary"
                    triggers="focus"
                  >
                    {{ item.detail.reject_reason ? item.detail.reject_reason:'' }}
                  </b-popover>
                  <div style="margin: 0 5px;" />
                  <b-button
                    :variant="item.detail['status'] === 'pending' ? 'warning' : item.detail['status'] === 'successful' ? 'success' :item.detail['status']? 'danger':''"
                    size="sm"
                    style="display: block; width: 140px"
                  > {{ $t(item.detail.status) }}</b-button>

                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="auto">
            <b-pagination
              v-model="currentpageTransactions"
              align="fill"
              size="sm"
              class="mb-1"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-modal
      v-model="confirmModal"
      :title="$t('invoice_confirm')"
      centered
      size="lg"
      no-close-on-backdrop
    >
      <template>
        <b-card
          class="mb-0 invoiceModal"
          :title="$t('invoice.brandName',{brand_name:getBrandName()})"
        >
          <b-row
            align-h="end"
            class="mt-n2 mb-1"
          >
            <b-col cols="auto">
              <span class="text-dark">
                ..
              </span>
            </b-col>
          </b-row>
          <b-row
            class="row-bg p-1 font-weight-bold"
            style="background-color: #e3ffd7;"
          >
            <b-col
              cols="12"
              class="text-primary"
            >
              <!-- <span>{{ $t('invoice.invoiceDetails') }}</span> -->
              <span>{{ $t('invoice.withdrawalFromMetatrader') }}</span>
            </b-col>
            <div
              v-if="modalInvoiceColumns"
              class="table-invoice-crypto-withdraw w-100 mt-1"
            >
              <b-table
                responsive
                stacked
                :items="modalInvoiceColumns"
                :fields="invoiceHeader"
              >
                <template
                  #cell(fee)="data"
                >
                  <div
                    class="text-danger"
                  >
                    <span>** </span>
                    <span>{{ $t('crypto_withdraw_invoice_fee_description') }}</span>
                  </div>
                </template>
              </b-table>
            </div>
          </b-row>
        </b-card>
      </template>
      <template #modal-footer>
        <b-button
          size="md"
          variant="success"
          :disabled="confirmPending"
          @click="withdrawalSendOtp"
        >
          <span v-if="!resendOtpLoading">
            {{ $t('widrawModal.confirm') }}
          </span>
          <b-spinner
            v-else
            small
            size="10"
            variant="light"
            type="grow"
          />
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="infoTransactionModal"
      :title="selectedTransaction ? $t('info_transaction_modal', { id: selectedTransaction[0].id }) : ''"
      hide-footer
      size="lg"
      modal-class="transaction-modal"
    >
      <b-alert
        v-if="selectedTransaction && selectedTransaction[0].detail.reject_reason"
        variant="danger"
        show
      >
        <h5 class="alert-heading">
          {{ $t('rejectDescription') }}:
        </h5>
        <div class="alert-body p-2">
          <p>
            {{ selectedTransaction && selectedTransaction[0].detail.reject_reason }}
          </p>
        </div>
      </b-alert>
      <!-- request info -->
      <h5 class="mt-1">
        {{ $t('request_info') }}
      </h5>
      <b-table
        hover
        responsive
        :items="selectedTransaction"
        :fields="transactionModalRequestFields"
      />
      <hr>
      <!-- request destination -->
      <h5 class="mt-2">
        {{ $t('account_destination') }}
      </h5>
      <b-table
        hover
        responsive
        :items="selectedTransaction"
        :fields="transactionModalAccountFields"
      />
    </b-modal>
    <b-modal
      v-model="otpModal"
      :title="$t('confirm_withdraw')"
      centered
      size="md"
      hide-footer
    >
      <validation-observer
        #default="{invalid}"
      >
        <b-row>
          <b-col>
            <div
              v-if="mobile"
              class="my-1"
            >
              {{ $t('withdraw_confirm_description', { mobile }) }}
            </div>
            <b-form
              class="auth-login-form mb-2"
            >
              <!-- pin -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="code">{{ $t('confirm_withdraw_code') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('confirm_withdraw_code')"
                  vid="code"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="code"
                      v-model="confirmCode"
                      name="code"
                      placeholder="0000"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-button
              block
              size="md"
              variant="warning"
              :disabled="verifyCounter !== 0"
              @click="withdrawalSendOtp"
            >
              <div v-if="!resendOtpLoading">
                <span>
                  {{ $t('resend') }}
                </span>
                <span v-if="verifyCounter !== 0">({{ convertSecondsToMinutes(verifyCounter) }})</span>
              </div>
              <b-spinner
                v-else
                small
                size="10"
                variant="light"
                type="grow"
              />
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              block
              size="md"
              :disabled="invalid || confirmCode.length < 4 || confirmPending"
              variant="success"
              @click="save"
            >
              <span v-if="!confirmPending">
                {{ $t('widrawModal.confirm') }}
              </span>
              <b-spinner
                v-else
                small
                size="10"
                variant="light"
                type="grow"
              />
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BOverlay, BFormInput, BForm, BFormGroup, BCard, BRow, BButton, BImg, BCardBody, BModal, BAlert, BPopover,
  BCol, BSpinner, BTable, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required, cryptoWalletAddress, min_value, numberOrDecimal, decimalPoints,
} from '@validations'
import { QrcodeStream } from 'vue-qrcode-reader'
import vSelect from 'vue-select'
import { convertSecondsToMinutes, numberWithCommas, toFixed } from '@/utils'
import MetatraderService from '@/modules/forex/service/apis'
import metatraderAccountDetail from '@/modules/forex/views/metatrader/metatraderAccountDetail.vue'
import moment from '@/libs/moment'
import { alphanumeric } from '@/mixins/alphanumeric'

const metaTrader = new MetatraderService()
export default {
  components: {
    BOverlay,
    BFormInput,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BButton,
    BImg,
    BCardBody,
    BCol,
    BModal,
    BSpinner,
    BAlert,
    QrcodeStream,
    BTable,
    BPagination,
    BPopover,
    vSelect,
    metatraderAccountDetail,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      overlay: {
        main: false,
        withdraw: false,
      },
      convertSecondsToMinutes,
      otpModal: false,
      confirmCode: '',
      resendOtpLoading: false,
      verifyCounter: 0,
      confirmModal: false,
      withdrawal: true,
      confirmPending: false,
      verfyCounterTimeout: null,
      required,
      min_value,
      cryptoWalletAddress,
      numberOrDecimal,
      decimalPoints,
      symbol: '',
      destinationAddress: '',
      items: [],
      error: '',
      showBarcode: false,
      modalInvoiceColumns: null,
      helpers: {
        numberWithCommas,
        toFixed,
      },
      currentpageTransactions: 1,
      perPageTransactions: 10,
      platform: [
        { title: 'metatrader 4', value: 'mt4' },
      ],
      accountsItems: [],
      currencyItems: [],
      networkItems: [],
      formData: {
        destinationAddress: '',
        account: {},
        platform: '',
        currency: '',
        network: '',
        amount: '',
      },
      accountDetail: {
        account: '',
        balance: '',
        equity: '',
        group: '',
        leverage: '',
        margin: '',
        platform: '',
      },
      infoTransactionModal: false,
      selectedTransaction: null,
    }
  },

  computed: {
    fields() {
      return [
        {
          label: this.$t('id'),
          key: 'automation_id',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('coin'),
          key: 'currency.currency',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('Network'),
          key: 'currency.network',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
          tdClass: 'nameOfTheClass',
        },

        {
          label: this.$t('date'),
          key: 'created_at',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('status'),
          key: 'detail.status',
          tdClass: 'nameOfTheClass',
        },
      ]
    },
    mobile() {
      return this.$store.state.profile.profileInfo.mobile
    },
    invoiceHeader() {
      return [
        {
          key: 'address',
          label: this.$t('address'),
        },
        {
          key: 'network',
          label: this.$t('Network'),
        },
        {
          key: 'currency',
          label: this.$t('currency'),
        },
        {
          key: 'amount',
          label: this.$t('Amount'),
        },
        {
          key: 'total',
          label: this.$t('total'),
        },
      ]
    },
    transactionModalRequestFields() {
      return [
        {
          label: this.$t('account'),
          key: 'account',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
        },
        {
          label: this.$t('currency'),
          key: 'currency',
        },
      ]
    },
    transactionModalAccountFields() {
      return [
        {
          label: this.$t('Wallet'),
          key: 'address_wallet',
        },
      ]
    },
  },

  watch: {
    '$store.state.Messaging.event': {
      async handler(newVal) {
        if (newVal === 'platform_forex_withdrawal_crypto') {
          await this.list()
          this.$store.dispatch('Messaging/setEvent', '')
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getData()
    this.getCurrency()
    this.list()
  },
  methods: {
    async getData() {
      this.overlay.main = true
      Promise.all([
        metaTrader.getPlatforms().then(res => {
          this.platform = res.data.results
        }),
        await metaTrader.getAccounts().then(res => {
          this.accountsItems = res.data.results
          // eslint-disable-next-line prefer-destructuring
          this.formData.account = res.data.results[0]
          // eslint-disable-next-line prefer-destructuring
          if (res.data.results[0]) this.accountDetail = res.data.results[0]

          this.formData.platform = this.platform.find(plat => plat.value === this.accountDetail.platform)
          if (this.$route.params.id) {
            const accountDetail = res.data.results.find(item => item.account === parseInt(this.$route.params.id, 10))
            this.formData.account = accountDetail
            this.accountDetail = accountDetail
            this.formData.platform = this.platform.find(plat => plat.value === accountDetail.platform)
          }
        })]).catch(err => {
        console.log(err)
      }).finally(() => {
        this.overlay.main = false
      })
    },
    async save() {
      this.confirmPending = true
      metaTrader.saveCryptoWithdrawal({
        ...this.formData,
        amount: this.formData.amount.replace(/,/g, ''),
        address_wallet: this.formData.destinationAddress,
        account: this.formData.account.account,
        currency: this.formData.currency.currency,
        platform: this.formData.platform.value,
        code: this.confirmCode,
      }).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        this.withdrawal = false
        this.otpModal = false
        this.confirmModal = false
        this.list()
      }).finally(() => {
        this.confirmPending = false
      })
    },
    withdrawalSendOtp() {
      this.resendOtpLoading = true
      const data = {
        currency: this.formData.currency.currency,
        amount: this.formData.amount.replace(/,/g, ''),
      }
      metaTrader.withdrawalSendOtp(data).then(res => {
        this.verifyCounter = res.data.results.expiration_time * 60
        clearTimeout(this.verfyCounterTimeout)
        this.countDownTimer()
        this.otpModal = true
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).catch(error => {
        if (error.status === 428) {
          this.otpModal = true
          this.verifyCounter = error.data.results.time
          clearTimeout(this.verfyCounterTimeout)
          this.countDownTimer()
        }
      }).finally(() => {
        this.resendOtpLoading = false
      })
    },
    countDownTimer() {
      if (this.verifyCounter > 0) {
        this.verfyCounterTimeout = setTimeout(() => {
          this.verifyCounter -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    onchangeAccountDetail(item) {
      this.accountDetail = item
      this.formData.platform = this.platform.find(plat => plat.value === item.platform)
    },
    onCurrencyChange(e) {
      this.networkItems = [{ network: e.network }]
      this.formData.network = e.network
    },
    async getCurrency() {
      await metaTrader.currencyList({ type: 'crypto' }).then(res => {
        const currencies = res.data.results.map(item => ({ ...item, currencyNetwork: `${item.currency} (${item.network})` }))
        this.currencyItems = currencies
        const [firstCurrency] = currencies
        this.formData.currency = firstCurrency
        this.networkItems = [{ network: firstCurrency.network }]
        this.formData.network = firstCurrency.network
      })
    },
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        this.showBarcode = false
        if (error.name === 'NotAllowedError') {
          this.error = 'barcodeError.camera-access'
        } else if (error.name === 'NotFoundError') {
          this.error = 'barcodeError.camera-error'
        } else if (error.name === 'NotReadableError') {
          this.error = 'barcodeError.use-camera'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'barcodeError.camera-installed'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'barcodeError.not-support'
        } else if (error.name === 'InsecureContextError') {
          this.error = 'barcodeError.use-HTTPS'
        } else {
          this.error = `ERROR: Camera error (${error.name})`
        }
      }
    },
    list() {
      this.overlay.withdraw = true
      metaTrader.withdrawalDepositList({ per_page: this.perPageTransactions, page: this.currentpageTransactions }).then(res => {
        this.items = res.data.results.data
        this.totalTransactions = res.data.results.paginate.total
        this.currentpageTransactions = res.data.results.paginate.current_page
        this.perPageTransactions = Number(res.data.results.paginate.per_page)
      }).finally(() => {
        this.overlay.withdraw = false
      })
    },
    onDecode(result) {
      this.formData.destinationAddress = result
      this.showBarcode = false
      this.error = ''
    },
    async  setInvoiceDetails() {
      const valid = await this.$refs.depositFormRef.validate()
      if (!valid) return
      this.confirmModal = true
      this.modalInvoiceColumns = [{
        address: this.formData.destinationAddress,
        network: this.formData.network,
        currency: this.formData.currency.currency,
        amount: this.helpers.numberWithCommas(this.formData.amount),
        total: this.helpers.numberWithCommas((this.formData.amount)),
      }]
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    numberFormat(value) {
      return this.helpers.numberWithCommas(value)
    },
    openInfoTransactionModal(val) {
      this.infoTransactionModal = true
      this.selectedTransaction = [{
        ...val,
        currency: val.currency.currency,
        amount: this.helpers.numberWithCommas(val.amount),
      }]
    },
  },

}
</script>
  <style lang="scss">
  .invoiceModal .card-title{
    color: $primary;
  }
  .card-border {
    border-right: 1px solid #e3e1e9;
  }
  .transaction-card {
    background-color: #efefef;
    padding: 15px;
    border-radius: 5px;
  }
  .nameOfTheClass {
    padding: 20px !important;
  }
  .table-invoice-crypto-withdraw .table{
    background: transparent !important;
  }
  .table-invoice-crypto-withdraw .table tbody{
    background: transparent !important;
  }
  .table-invoice-crypto-withdraw .table tr{
    background: transparent !important;
  }
  .table-invoice-crypto-withdraw .table td{
    color: #283046 !important;
    padding: 6px !important;
  }
  .table-invoice-crypto-withdraw .table td::before{
    width: 14rem !important;
  }
  @media screen and (max-width:1024px) {
    .table-invoice-crypto-withdraw .table td::before{
      width: 4rem !important;
    }
  }
  </style>
